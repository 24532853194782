import React, { useState } from "react";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaLocationDot,
  FaInstagram,
  FaYoutube,
  FaPinterestP,
  FaXTwitter
} from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import { IoCallSharp } from "react-icons/io5";
import { Link, useNavigate,useLocation } from "react-router-dom";
import axios from "axios";
import stdCodes from "../components/utils/data/stdCodes.json";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InnerPageBanner from "../components/shared/innerPageBanner";
import ImageIcons from "../components/imageComponent/ImageIcons";
import Layout from "../components/shared/layout";
import { validateEmail } from "../utils/formFunctions";
import Loader from "../components/shared/loader";
import SEO from "../components/shared/seo";
import { windowScroll } from "../components/utils/windowScroll";

const Contact = () => {
  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [error, setError] = useState({
    email: false,
    phone: false,
    message: "",
    success: false,
  });

  const [formInput, setFormInput] = useState({
    firstName: "",
    lastName: "",
    email: "",
    subject: "",
    phone: "",
    message: "",
    stdCode: "+91",
  });

  const firstNameHandler = (e) => {
    const inputValue = e.target.value;
    const alphaValue = inputValue.replace(/[^A-Za-z ]/g, ""); // Alphabatical charcter should be allowed
    // Capitalize the first letter of each word
    const capitalizedValue = alphaValue.replace(/\b\w/g, (match) =>
      match.toUpperCase()
    );
    if (capitalizedValue.length <= 30) {
      setFormInput((prevState) => ({
        ...prevState,
        firstName: capitalizedValue,
      }));
      setError((prevState) => ({ ...prevState, message: "" }));
    }
  };

  const lastNameHandler = (e) => {
    const inputValue = e.target.value;
    const alphaValue = inputValue.replace(/[^A-Za-z]/g, "");
    if (alphaValue.length <= 30) {
      setFormInput((prevState) => ({ ...prevState, lastName: alphaValue }));
      setError((prevState) => ({ ...prevState, message: "" }));
    }
  };

  const handlePhoneInputChange = (e) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/\D/g, ""); // Remove non-digit characters

    if (numericValue.length <= 10) {
      setFormInput((prevState) => ({ ...prevState, phone: numericValue }));
      setError((prevState) => ({ ...prevState, phone: false, message: "" }));
    }
    const repeatingDigitsRegex = /(\d)\1{9,}/;
    if (repeatingDigitsRegex.test(numericValue)) {
      // If repeating digits are found, display an error message
      setError((prevState) => ({ ...prevState, phone: true, message: "Phone number cannot consist of repeating digits." }));
    }
  };


  const handleSubjectInputChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= 50) {
      setFormInput((prevState) => ({ ...prevState, subject: inputValue }));
      setError((prevState) => ({ ...prevState, message: "" }));
    }
  };

  const submissionHandler = (e) => {
    e.preventDefault();
    setDisabled(true);
    setError((prevState) => ({ ...prevState, message: "" }));

    if (
      !formInput.firstName ||
      !formInput.lastName ||
      !formInput.email ||
      !formInput.phone ||
      !formInput.subject
    ) {
      setError((prevState) => ({
        ...prevState,
        success: false,
        message: "Fields must not be empty!",
      }));
      setDisabled(false);
    } else if (!validateEmail(formInput.email)) {
      setError((prevState) => ({
        ...prevState,
        email: true,
        message: "Email is invalid!",
      }));
      setDisabled(false);
    } else if (formInput.phone.length < 10) {
      setError((prevState) => ({
        ...prevState,
        phone: true,
        message: "Phone number is invalid!",
      }));
      setDisabled(false);
    } else if (/^(\d)\1+$/.test(formInput.phone)) {
      setError((prevState) => ({
        ...prevState,
        phone: true,
        message: "Phone number must be valid !",
      }));
      setDisabled(false);
    } else {

      axios.post(`${process.env.REACT_APP_URL}user/sendemail`, {
        firstName: formInput.firstName,
        lastName: formInput.lastName,
        email: formInput.email,
        number: `${formInput.stdCode} ${formInput.phone}`,
        message: formInput.message,
        subject: formInput.subject,
      })
        .then((response) => {
          console.log("Response:", response.data);
          setError((prevState) => ({ ...prevState, success: true }));
          setTimeout(() => {
            setError((prevState) => ({ ...prevState, success: false }));
            setDisabled(false);
            setFormInput({
              firstName: "",
              lastName: "",
              email: "",
              phone: "",
              message: "",
              subject: "",
              stdCode: "+91",
            });
            navigate("/thanks");
            localStorage.setItem('currentPathname', pathname);

            windowScroll();
          },);
        })
        .catch((error) => {
          console.error("Error:", error);
          setDisabled(false);
        });
    }
  };

  return (
    <>
    <SEO title="Contact Us | My Internet Services" description="Contact us at MyInternet for inquiries, support, or partnerships. Reach out today and let's discuss how we can assist you with your internet needs"/>
    <Layout>
      <InnerPageBanner title="Contact Us" innerPageBanner={ImageIcons.Contactus} />

      <section>
        <div className="container relative">
          <div className="grid lg:grid-cols-2 gap-[40px] items-center">
            <div className="lg:max-w-[500px] max-w-full">
              <div className="text-[#f48533] uppercase mb-[8px]">
                Contact With Us
              </div>
              <h2>Get in touch with us</h2>
              <p className="text-[19px]">
                I'm a paragraph. Click here to add your own text and edit me.
                I’m a great place for you to tell a story and let your users
                know a little more about you.
              </p>
              <div className="flex flex-wrap gap-[10px]">
                <Link to='https://www.facebook.com/myinternetMohali/' title="Facebook" target="_blank" className='text-[18px] text-[#f48533] hover:text-[#fff] w-[45px] h-[45px] rounded-full border border-[#f48533] hover:border-[#F48533] hover:bg-[#F48533] flex justify-center items-center'> <FaFacebookF /></Link>
                <Link to='https://www.instagram.com/myinternet__/' title="Instagram" target="_blank" className='text-[18px] text-[#f48533] hover:text-[#fff] w-[45px] h-[45px] rounded-full border border-[#f48533] hover:border-[#F48533] hover:bg-[#F48533] flex justify-center items-center'> <FaInstagram /></Link>
                <Link to='https://twitter.com/MyInternet_' title="Twitter" target="_blank" className='text-[18px] text-[#f48533] hover:text-[#fff] w-[45px] h-[45px] rounded-full border border-[#f48533] hover:border-[#F48533] hover:bg-[#F48533] flex justify-center items-center'> <FaXTwitter /></Link>
                <Link to='https://www.youtube.com/@MyInternet_' title="Youtube" target="_blank" className='text-[18px] text-[#f48533] hover:text-[#fff] w-[45px] h-[45px] rounded-full border border-[#f48533] hover:border-[#F48533] hover:bg-[#F48533] flex justify-center items-center'> <FaYoutube /></Link>
                <Link to='https://www.linkedin.com/company/my-internet/' title="Linkedin" target="_blank" className='text-[18px] text-[#f48533] hover:text-[#fff] w-[45px] h-[45px] rounded-full border border-[#f48533] hover:border-[#F48533] hover:bg-[#F48533] flex justify-center items-center'> <FaLinkedinIn /></Link>
                <Link to='https://www.pinterest.com/myinternet_/' title="Pinterest" target="_blank" className='text-[18px] text-[#f48533] hover:text-[#fff] w-[45px] h-[45px] rounded-full border border-[#f48533] hover:border-[#F48533] hover:bg-[#F48533] flex justify-center items-center'> <FaPinterestP /></Link>
              </div>
            </div>
            <div>
              <>
                {disabled && <Loader />}
                <form className="grid md:grid-cols-2 grid-cols-1 xl:gap-[25px] gap-[20px]" onSubmit={submissionHandler}>
                  <p
                    className={`text-[14px] text-left text-[#FF0000] font-semibold mb-0 sm:col-span-2 col-span-1 ${error.message ? "block" : "hidden "
                      }`}
                  >
                    {error.message}
                  </p>
                  <div>
                    <lable
                      className="mb-[8px] inline-block"
                      htmlFor="inputFirstName"
                    >
                      First Name
                    </lable>
                    <input
                      id="inputFirstName"
                      disabled={disabled}
                      value={formInput.firstName}
                      onChange={(e) => firstNameHandler(e)}
                      className="w-full h-[50px] border border-[#f7dfcd] bg-[#f4853308] px-[15px] rounded-[5px] focus:outline-none focus:border-[#f48533] focus:bg-[#fbf2ee]"
                    />
                  </div>
                  <div>
                    <lable
                      className="mb-[8px] inline-block"
                      htmlFor="inputLastName"
                    >
                      Last Name
                    </lable>
                    <input
                      type="text"
                      disabled={disabled}
                      id="inputLastName"
                      value={formInput.lastName}
                      onChange={(e) => lastNameHandler(e)}
                      className="w-full h-[50px] border border-[#f7dfcd] bg-[#f4853308] px-[15px] rounded-[5px] focus:outline-none focus:border-[#f48533] focus:bg-[#fbf2ee]"
                    />
                  </div>
                  <div>
                    <lable
                      className="mb-[8px] inline-block"
                      htmlFor="inputEmail"
                    >
                      Email *
                    </lable>
                    <input
                      minRows={3}
                      maxRows={5}
                      disabled={disabled}
                      error={error.email}
                      placeholder="example@gmail.com"
                      id="inputEmail"
                      value={formInput.email}
                      onChange={(e) => {
                        setFormInput((prevState) => ({
                          ...prevState,
                          email: e.target.value,
                        }));
                        setError((prevState) => ({
                          ...prevState,
                          email: false,
                          message: "",
                        }));
                      }}
                      className="w-full h-[50px] border border-[#f7dfcd] bg-[#f4853308] px-[15px] rounded-[5px] focus:outline-none focus:border-[#f48533] focus:bg-[#fbf2ee]"
                    />
                  </div>
                  <div>
                    <lable
                      className="mb-[8px] inline-block"
                      htmlFor="inputSubject"
                    >
                      Subject *
                    </lable>
                    <input
                      disabled={disabled}
                      id="inputSubject"
                      value={formInput.subject}
                      onChange={(e) => handleSubjectInputChange(e)}
                      className="w-full h-[50px] border border-[#f7dfcd] bg-[#f4853308] px-[15px] rounded-[5px] focus:outline-none focus:border-[#f48533] focus:bg-[#fbf2ee]"
                    />
                  </div>
                  <div className="sm:col-span-2 col-span-1">
                    <lable
                      className="mb-[8px] inline-block"
                      htmlFor="inputPhone"
                    >
                      Phone *
                    </lable>
                    <div className="flex">
                      <select
                        className="h-[50px] px-[6px] min-w-[100px] !border-r-0 rounded-r-none border border-[#f7dfcd] bg-[#f4853308] rounded-[4px] focus:outline-none"
                        value={formInput.stdCode}
                        onChange={(e) =>
                          setFormInput((prevState) => ({
                            ...prevState,
                            stdCode: e.target.value,
                          }))
                        }
                      >
                        {stdCodes.map((item, i) => (
                          <option
                            key={i}
                            value={item.dial_code}
                            className="h-[20px]"
                          >
                            {item.dial_code} ({item.code})
                          </option>
                        ))}
                      </select>

                      <input
                        disabled={disabled}
                        error={error.phone}
                        id="inputPhone"
                        value={formInput.phone}
                        onChange={(e) => handlePhoneInputChange(e)}
                        className="w-full rounded-l-none h-[50px] border border-[#f7dfcd] bg-[#f4853308] px-[15px] rounded-[5px] focus:outline-none focus:border-[#f48533] focus:bg-[#fbf2ee]"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2 col-span-1">
                    <lable
                      className="mb-[8px] inline-block"
                      htmlFor="inputMessage"
                    >
                      Leave Us a Message
                    </lable>

                    <textarea
                      disabled={disabled}
                      error={error.msg}
                      id="inputMessage"
                      value={formInput.message}
                      placeholder="Write your message here..."
                      onChange={(e) => {
                        setFormInput((prevState) => ({
                          ...prevState,
                          message: e.target.value,
                        }));
                        setError((prevState) => ({
                          ...prevState,
                          msg: false,
                          message: "",
                        }));
                      }}
                      className="w-full inline-block h-[100px] border border-[#f7dfcd] bg-[#f4853308] p-[15px] rounded-[5px] focus:outline-none focus:border-[#f48533] focus:bg-[#fbf2ee]"
                    />
                  </div>
                  <div>
                    <button className="px-[50px] text-[18px] py-[16px] duration-500 font-medium text-[#fff] bg-[#f48533] rounded-[10px] hover:bg-[#000] ease inline-block"
                      type="submit"
                    >
                      Submit Message
                    </button>
                  </div>
                </form>
              </>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-[#fff5ee]">
        <div className="container">
          <div className="flex flex-col items-center">
            <div className="grid w-full grid-cols-1 xl:gap-[30px] gap-[20px] md:grid-cols-3">
              <div className="relative group flex flex-col mt-[45px] items-center gap-3 shadow-[5px_8.66px_30px_0px_rgba(0,0,0,0.1)] xl:px-[50px] px-[30px] py-[50px] justify-center text-center bg-white rounded-3xl shadow-main duration-500 ">
                <span className="w-[90px] h-[90px] absolute md:top-[-45px] top-[-35px]">
                  <IoMdMail className="!text-[70px] md:!text-[90px] border border-[#f48533] bg-[#fff] p-[20px] rounded-full text-[#f48533] group-hover:text-[#f96819] group-hover:mt-[10px] duration-500 group-hover:animate-bounce" />
                </span>
                <h5 className="text-[22px] md:text-[26px] mb-[5px] mt-0 md:mt-[15px]">
                  Email
                </h5>
                <Link
                  to={`mailto:info@myinternet.services`}
                  className="max-lg:text-[16px]"
                >
                  info@myinternet.services
                </Link>
              </div>
              <div className="relative group flex flex-col mt-[45px] items-center gap-3 shadow-[5px_8.66px_30px_0px_rgba(0,0,0,0.1)] xl:px-[50px] px-[30px] py-[50px] justify-center text-center bg-white rounded-3xl shadow-main duration-500 ">
                <span className="w-[90px] h-[90px] absolute md:top-[-45px] top-[-35px]">
                  <IoCallSharp className="!text-[70px] md:!text-[90px] border border-[#f48533] bg-[#fff] p-[20px] rounded-full text-[#f48533] group-hover:text-[#f96819] group-hover:mt-[10px] duration-500 group-hover:animate-bounce" />
                </span>
                <h5 className="text-[22px] md:text-[26px] mb-[5px] mt-0 md:mt-[15px]">
                  Phone
                </h5>
                <Link to={`tel:+91 9464001234`} className="max-lg:text-[16px]">
                  +91 9464001234
                </Link>
              </div>
              <div className="relative group flex flex-col mt-[45px] items-center gap-3 shadow-[5px_8.66px_30px_0px_rgba(0,0,0,0.1)] xl:px-[50px] px-[30px] py-[50px] justify-center text-center bg-white rounded-3xl shadow-main duration-500 ">
                <span className="w-[90px] h-[90px] absolute md:top-[-45px] top-[-35px]">
                  <FaLocationDot className="!text-[70px] md:!text-[90px] border border-[#f48533] bg-[#fff] p-[20px] rounded-full text-[#f48533] group-hover:text-[#f96819] group-hover:mt-[10px] duration-500 group-hover:animate-bounce" />
                </span>
                <h5 className="text-[22px] md:text-[26px] mb-[5px] mt-0 md:mt-[15px]">
                  Location
                </h5>
                <div className="max-lg:text-[16px]">
                  Plot No. 205, Near Markanda Complex, Opp. Punjabi University,
                  Patiala | Punjab 147002
                </div>
                <div className="max-lg:text-[16px]">
                  B-70, Phase 7, Industrial Area, Sector 74, Sahibzada Ajit Singh Nagar, Punjab 160055
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="p-0">
        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13720.735668876416!2d76.6943375!3d30.7132296!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fefdb9205b8a1%3A0xff54aee441605ba3!2sMy%20Internet!5e0!3m2!1sen!2sin!4v1720176158899!5m2!1sen!2sin"
          className="h-[400px] lg:h-[500px] bg-[#e8eaed]"
          width="100%"
          height=""
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </section>

      <ToastContainer />
    </Layout>
    </>
  );
};

export default Contact;