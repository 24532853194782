import React, { useState, useRef, useEffect } from 'react';
import Layout from '../components/shared/layout';
import InnerPageBanner from '../components/shared/innerPageBanner';
import ImageIcons from '../components/imageComponent/ImageIcons';
import service from '../components/utils/data/service.json'
import { useLocation, useParams, useNavigate } from "react-router-dom"
import { IoCheckmarkDoneSharp } from 'react-icons/io5';
import WeAreLocated from '../components/home/weAreLocated';
import { FaRocket } from "react-icons/fa6";
import Testimonial from '../components/home/testimonial';
import { FaMinus, FaPlus } from 'react-icons/fa';
import Button from '../components/shared/button';
import SEO from '../components/shared/seo';

const ServicesDetails = () => {
  const { type } = useParams();
  const navigate = useNavigate()

  const pathname = useLocation().pathname;

  const [serviceDetails, setServiceDetails] = useState(null);

  useEffect(() => {
    const selectedService = service.find(service => service.type === type);
    console.log("selectedService", selectedService)
    setServiceDetails(selectedService);
  }, [type, pathname])

  console.log("serviceDetails", serviceDetails)


  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  if (!serviceDetails) {
    navigate('*');
  }

  const AccordionItem = ({ handleToggle, active, faq }) => {
    const contentRef = useRef(null);
    const [height, setHeight] = useState('0px');

    useEffect(() => {
      if (contentRef.current) {
        setHeight(active ? `${contentRef.current.scrollHeight}px` : '0px');
      }
    }, [active]);



    return (

      <div className="mb-[20px] last:mb-0 shadow-[0px_3px_15px_0px_rgba(0,0,0,0.1)] overflow-hidden rounded-[10px]">
        <div
          className={`p-[20px] flex justify-between items-center cursor-pointer ${active ? 'bg-[#f48533] text-white' : ''}`}
          onClick={handleToggle}
        >
          <h5 className="mb-0 lg:text-[20px] text-[18px] ">{faq.question}</h5>
          <span>{active ? <FaMinus /> : <FaPlus />}</span>
        </div>
        <div
          ref={contentRef}
          className={`overflow-hidden transition-all duration-300`}
          style={{ maxHeight: height }}
        >
          <div className="p-[20px] ">
            <p className='mb-0'>{faq.answer}</p>
            {faq.answerList && <ul className='mt-[15px]'>
              {faq.answerList.map((faq, i) => (
                <li className='mb-[8px] text-[16px] relative pl-[25px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' /> {faq}</li>
              ))}
            </ul>
            }
          </div>
        </div>
      </div>

    );
  };

  return (
    <>
      <SEO title={serviceDetails?.metaTitle} description={serviceDetails?.description} keywords={serviceDetails?.keywords} />
      <Layout>
        <InnerPageBanner title={serviceDetails?.title} headingH2='headingH2' innerPageBanner={serviceDetails?.image} />
        {(serviceDetails?.description) &&
          <section className='pb-0'>
            <div className='container'>
              <p className="relative mb-[50px] font-normal shadow-[0px_2px_10px_#ddd] p-[35px] after:absolute after:content-[''] after:w-[50px] after:hover:w-[calc(100%_+_10px)] after:h-[50px] after:hover:h-[calc(100%_+_10px)] after:duration-700 after:border-r-[4px] after:border-r-[#e35f21] after:border-b-[4px] after:border-b-[#e35f21] after:border-solid after:right-0 after:bottom-0  before:absolute before:content-[''] before:w-[50px] before:hover:w-[calc(100%_+_10px)] before:h-[50px] before:hover:h-[calc(100%_+_10px)] before:duration-700 before:border-l-[4px] before:border-l-[#e35f21] before:border-t-[4px] before:border-t-[#e35f21] before:border-solid before:left-0 before:top-0">{serviceDetails?.description}</p>
            </div>
          </section>
        }
        {(serviceDetails?.heading && serviceDetails?.headingdescription) &&
          <section className='relative'>
            <div className='absolute w-[220px] left-[20px] bottom-[20px] animate-[animate-bounceTop_5s_infinite_ease-in-out_alternate] max-lg:opacity-70'>
              <img className='object-cover rotate-180' src={ImageIcons.aboutShape} alt='Shape' />
            </div>
            <div className='container '>
              <div className='flex lg:flex-nowrap flex-wrap lg:gap-[50px] gap-[40px] items-center'>
                <div className='lg:w-[50%] max-lg:order-2 w-full lg:py-[40px] py-0 relative ' >
                  <div className='relative z-10'>
                    <h1>{serviceDetails?.heading}</h1>

                    <p className='text-black'>{serviceDetails?.headingdescription}</p>
                  </div>
                </div>
                <div className='md:min-h-[500px] max-lg:order-1 sm:min-h-[400px] md:mr-[25px] mr-[15px] min-h-[300px] relative lg:w-[50%] w-full after:content-[""] after:absolute after:rounded-[20px] md:after:top-[-25px] after:top-[-15px] md:after:right-[-25px] after:right-[-15px] after:w-[calc(100%_-_50%)] after:z-[-1] after: after:bg-[#f38432] after:h-full'>
                  <img className='object-cover h-full w-full absolute rounded-[20px]' src={ImageIcons.leaseLine} alt='Lease Line' />
                </div>
              </div>
            </div>
          </section>
        }
        {(serviceDetails?.differenttypestitle && serviceDetails?.differenttypesdescription && serviceDetails?.types) &&

          <section className='bg-[#fff3ea]'>
            <div className='container'>
              <h2>{serviceDetails?.differenttypestitle}</h2>
              <p>{serviceDetails?.differenttypesdescription}</p>
              <div>
                {serviceDetails?.types?.map((item, index) => {
                  return (
                    <div className='mb-3 '>
                      {item?.title &&
                        <h3 className='text-[22px] font-semibold text-gray-900 mb-[10px]'>{item?.title}</h3>
                      }
                      <p className='text-gray-600 mb-0'>{item.description}</p>
                    </div>
                  )
                })
                }
              </div>
            </div>
          </section>
        }
        {(serviceDetails?.Ourservices || serviceDetails?.Ourservice || serviceDetails?.OurserviceList) && <section>
          <div className='container'>
            <div className='mb-[30px]'>
              <h2 className='mb-[10px]'>{serviceDetails?.Ourservices}</h2>
              {(serviceDetails?.Ourservicesdescription) &&
                <p className='mb-0'>{serviceDetails?.Ourservicesdescription}</p>
              }
            </div>

            <div>
              {serviceDetails?.Ourservice?.map((item) => {
                return (
                  <div className='mb-[30px] last:mb-0'>
                    {item?.title &&
                      <h3 className='text-[22px] font-semibold text-gray-900 mb-[10px]'>{item?.title}</h3>
                    }
                    <p className='text-gray-600 mb-0'>{item.description}</p>
                  </div>
                )
              })
              }

              <ul className='empty:hidden'>
                {serviceDetails?.OurserviceList?.map((item) => {
                  return (
                    <li className='relative pl-[25px] mb-[15px] last:mb-0'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />{item?.title}</li>
                  )
                })
                }
              </ul>
            </div>
          </div>
        </section>
        }

        {(serviceDetails?.managenetworktitle && serviceDetails?.managenetworkdescription) &&
          <section className='pb-0'>
            <div className='container'>
              <h2>{serviceDetails?.managenetworktitle}</h2>
              <p className='mb-0'>{serviceDetails?.managenetworkdescription}</p>
            </div>
          </section>
        }
        {(serviceDetails?.IPTV && serviceDetails?.IPTVDescription) &&
          <section className='py-0'>
            <div className='container'>
              <h2>{serviceDetails?.IPTV}</h2>
              <p className='mb-0'>{serviceDetails?.IPTVDescription}</p>
            </div>
          </section>
        }
        {(serviceDetails?.keyfeature && serviceDetails?.keyfeatures) &&
          <section className={`${(pathname === '/service/cloud-cam' || pathname === '/service/VoIP-intercom') ? 'p-0' : ''}`}>
            <div className='container'>
              <h2>{serviceDetails?.keyfeature}</h2>

              {serviceDetails?.keyfeatures?.map((item, index) => {
                return (
                  <div className='mb-[15px] last:mb-0'>
                    <h3 className='text-[22px] font-semibold text-gray-900 mb-[10px]'>{item?.title}</h3>
                    <p className='mb-0'>{item?.description}</p>
                  </div>
                )
              })
              }
            </div>
          </section>
        }
        {(serviceDetails?.keylist) &&
          <section className={pathname === '/service/cdn-services' ? 'p-0' : ''}>
            <div className='container'>
              <h2>{serviceDetails?.keyfeature}</h2>
              <ul>
                {serviceDetails?.keylist?.map((item, index) => {
                  return (
                    <li className='relative pl-[25px] mb-[15px] last:mb-0'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />{item?.description}</li>
                  )
                })
                }
              </ul>
            </div>
          </section>
        }
        {(serviceDetails?.work || serviceDetails?.IPTVdescription || serviceDetails?.descriptiondetail) &&
          <section>
            <div className='container'>
              <h2>{serviceDetails?.work}</h2>
              <p>{serviceDetails?.IPTVdescription}</p>

              <div>
                <ul>
                  {serviceDetails?.descriptiondetail?.map((item, index) => {
                    return (
                      <div className='mb-[20px] last:mb-0'>
                        <h3 className='text-[22px] font-semibold text-gray-900 mb-[10px]'>{item?.title}</h3>
                        <li className='relative pl-[25px] mb-[15px] last:mb-0'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />{item?.description}</li>
                      </div>
                    )
                  })
                  }
                </ul>
              </div>
            </div>
          </section>
        }
        {/* {(serviceDetails?.Heading1 && serviceDetails?.headingdescription1 && serviceDetails?.additionalInfo) && */}
        <section className="relative bg-cover bg-no-repeat after:absolute after:content-[''] after:w-full after:h-full 
            after:left-0 after:top-0 after:z-[-1] after:bg-[linear-gradient(-45deg,#fff4e6_0%,#f2f2fd_100%)]" style={{ backgroundImage: `url(${ImageIcons.servicesBg})` }}>
          <div className='container '>
            <div className="mb-[50px] text-center">
              <h2>{serviceDetails?.Heading1}</h2>
              {serviceDetails?.headingdescription1 &&
                <p className='text-black'>{serviceDetails?.headingdescription1}</p>
              }
            </div>
            <div className="grid xl:grid-cols-5 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4">
              {serviceDetails?.additionalInfo?.map((item, index) => {
                return (
                  <div className='bg-[#fff] border border-t-4 border-[#e3e6f0] hover:border-blue-600 first:hover:border-[#796eff] [&:nth-child(2)]:hover:border-[#21c87a] [&:nth-child(3)]:hover:border-[#796eff] [&:nth-child(4)]:hover:border-[#da4b48] [&:nth-child(5)]:hover:border-[#1c6be1] p-5 rounded-lg mb-3 '>
                    {item?.title &&
                      <h3 className='text-[22px] font-semibold text-gray-900 mb-[10px]'>{item?.title}</h3>
                    }
                    <p className='text-gray-600 mb-0'>{item.description}</p>
                  </div>
                )
              })
              }
            </div>
          </div>
        </section>
        {/* } */}
        {(serviceDetails?.feature || serviceDetails?.featuredescription || serviceDetails?.features || serviceDetails?.featuredescriptionabout) &&
          <section className='relative pb-0'>
            <div className='absolute max-lg:hidden w-[250px] right-0 bottom-[20px] animate-[animate-bounceTop_5s_infinite_ease-in-out_alternate] max-lg:opacity-30 opacity-40'>
              <img className='object-cover' src={ImageIcons.inneraboutShape} alt='Shape' />
            </div>
            <div className='container'>
              <div className='grid lg:grid-cols-2 grid-cols-1 lg:gap-[50px] gap-[40px] items-center'>
                <div className='max-lg:order-2'>
                  <img className='mx-auto' src={ImageIcons.chooseLeasedLine} alt='Choose Leased Line' />
                </div>
                <div className='max-lg:order-1'>
                  <h2>{serviceDetails?.feature}</h2>
                  <p>{serviceDetails?.featuredescription}</p>

                  <ul className=''>
                    {serviceDetails?.features?.map((item, index) => {
                      return (
                        <li className='relative pl-[25px] mb-[15px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />{item?.title}</li>
                      )
                    })
                    }
                  </ul>

                  {serviceDetails?.featuredescriptionabout?.map((item, index) => {
                    return (
                      <div className='mb-4 '>
                        {item?.title &&
                          <h3 className='text-[22px] font-semibold text-gray-900 mb-[10px]'>{item?.title}</h3>
                        }
                        <p className='text-gray-600 mb-0'>{item.description}</p>
                      </div>
                    )
                  })
                  }
                </div>
              </div>
            </div>
          </section>
        }
        {(serviceDetails?.areacoveragetitle && serviceDetails?.areacoveragedescription && serviceDetails?.urban) &&
          <section>
            <div className='container'>
              <h2>{serviceDetails?.areacoveragetitle}</h2>
              <p>{serviceDetails?.areacoveragedescription}</p>

              <div>

                {serviceDetails?.urban?.map((item, index) => {
                  return (
                    <div className='mb-4 '>
                      {item?.heading &&
                        <h3 className='text-[22px] font-semibold text-gray-900 mb-[10px]'>{item?.heading}</h3>
                      }

                      <ul>
                        {item?.urbanList?.map((item, index) => {
                          return (
                            <li className='relative pl-[25px] mb-[15px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />{item?.title}</li>
                          )
                        })
                        }
                      </ul>

                    </div>
                  )
                })
                }
              </div>
            </div>
          </section>
        }
        {(serviceDetails?.safetyandsecuritytitle && serviceDetails?.safetyandsecuritydescription && serviceDetails?.safety) &&

          <section className="relative bg-no-repeat bg-fixed after:absolute after:content-[''] after:w-full after:h-full after:right-0 after:top-0 after:bg-[#fff5ee] after:opacity-90 bg-cover" style={{ backgroundImage: `url(${ImageIcons.expansion})` }}>
            <div className='container relative z-10'>
              <div className='text-center mb-[40px]'>
                <h2 className='text-center mb-[10px]'>{serviceDetails?.safetyandsecuritytitle}</h2>
                <p className=''>{serviceDetails?.safetyandsecuritydescription}</p>
              </div>

              <div className='grid md:grid-cols-3 xl:gap-[40px] lg:gap-[30px] md:gap-[20px] gap-[40px]'>
                {serviceDetails?.safety?.map((item, index) => {
                  return (
                    <div className='bg-[#fff] text-center py-[50px] lg:px-[40px] px-[25px] rounded-[10px] mt-[40px] group border-[#b7b7b7] hover:border-[#141416] border-[1px] duration-500'>
                      <span className='w-[80px] h-[80px] -mt-[90px] mb-[20px] shadow-[0px_0px_0px_4px_rgba(244,133,51,0.25)] bg-white rounded-full flex justify-center items-center mx-auto text-[28px] text-[#f48533] duration-700 group-hover:rotate-[360deg] group-hover:scale-[1.1] group-hover:text-white group-hover:bg-[#f48533]'><FaRocket /></span>
                      <h3 className='pt-2'>{item.title}</h3>
                      <p className='mb-0'>{item?.description}</p>
                    </div>
                  )
                })
                }
              </div>

            </div>
          </section>
        }

        {(serviceDetails?.title === "Internet Leased Line")?(!<WeAreLocated />):<WeAreLocated/>}
        {(serviceDetails?.ourplans && serviceDetails?.plans) &&
          <section className="relative bg-no-repeat bg-fixed after:absolute after:content-[''] after:w-full after:h-full after:right-0 after:top-0 after:bg-[#fff5ee] after:opacity-90 bg-cover" style={{ backgroundImage: `url(${ImageIcons.expansion})` }}>
            <div className='container relative z-10'>
              <h2 className='text-center mb-[40px]'>{serviceDetails?.ourplans}</h2>
              <p className='text-center' >{serviceDetails?.descriptionplans}</p>
              <div className='grid md:grid-cols-3 xl:gap-[40px] lg:gap-[30px] md:gap-[20px] gap-[40px]'>
                {serviceDetails?.plans?.map((item, index) => {
                  return (
                    <div className='bg-[#fff] text-center py-[50px] lg:px-[40px] px-[25px] rounded-[10px] mt-[40px] group border-[#b7b7b7] hover:border-[#141416] border-[1px] duration-500'>
                      <span className='w-[80px] h-[80px] -mt-[90px] mb-[20px] shadow-[0px_0px_0px_4px_rgba(244,133,51,0.25)] bg-white rounded-full flex justify-center items-center mx-auto text-[28px] text-[#f48533] duration-700 group-hover:rotate-[360deg] group-hover:scale-[1.1] group-hover:text-white group-hover:bg-[#f48533]'><FaRocket /></span>
                      <h3 className='pt-2'>{item.title}</h3>
                      <p className='mb-0'>{item?.description}</p>
                    </div>
                  )
                })
                }
              </div>

            </div>
          </section>
        }
        {(serviceDetails?.speed && serviceDetails?.speedcapacity) &&
          <section>
            <div className='container'>
              <h2>{serviceDetails?.speed}</h2>
              <ul>
                {serviceDetails?.speedcapacity?.map((item, index) => {
                  return (
                    <li className='relative pl-[25px] mb-[15px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />{item?.title}</li>
                  )
                })
                }
              </ul>

            </div>
          </section>
        }

        <div className='bg-[#f7f5f4]'>
          <Testimonial />
        </div>
        {(serviceDetails?.customertitle && serviceDetails?.customerdescription && serviceDetails?.customer) &&
          <section className='pb-0'>
            <div className='container'>
              <h2>{serviceDetails?.customertitle}</h2>

              <ul>
                {serviceDetails?.customer?.map((item, index) => {
                  return (
                    <li className='relative pl-[25px] mb-[15px] last:mb-0'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />{item?.title}</li>
                  )
                })
                }
              </ul>
              <p className='mt-5'>{serviceDetails?.customerdescription}</p>
            </div>
          </section>
        }

        {(serviceDetails?.application && serviceDetails?.applicationfeature) &&
          <section className='pb-0'>
            <div className='container'>
              <h2>{serviceDetails?.application}</h2>

              <ul>
                {serviceDetails?.applicationfeature?.map((item) => {
                  return (
                    <li className='relative pl-[25px] mb-[15px] last:mb-0'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />{item?.title}</li>
                  )
                })
                }
              </ul>
            </div>
          </section>
        }

        {(serviceDetails?.industries_serve || serviceDetails?.industries_description || serviceDetails?.industries) &&
          <section className='pb-0'>
            <div className='container'>
              <h2>{serviceDetails?.industries_serve}</h2>
              <p>{serviceDetails?.industries_description}</p>
              {
                serviceDetails?.industries?.map((item) => (
                  <div className='mb-[30px] last:mb-0'>
                    <h3 className='text-[22px] font-semibold text-gray-900 mb-[10px]'>{item?.title}</h3>
                    <p className='mb-0'>{item?.description}</p>
                  </div>
                ))
              }
            </div>
          </section>
        }
        {/* {(serviceDetails?.managenetworktitle && serviceDetails?.managenetworkdescription) &&
        <section className='py-0'>
          <div className='container'>
            <h2>
              {serviceDetails?.managenetworktitle}
            </h2>
            <p className='pb-0'>{serviceDetails?.managenetworkdescription}</p>
          </div>
        </section>
      } */}

        {(serviceDetails?.serviceFaq) &&
          <section>
            <div className='container lg:mb-[80px] md:mb-[70px] mb-[40px]'>
              <div className='bg-[#633b25] text-white md:p-[50px] p-[30px] flex md:flex-nowrap flex-wrap justify-between items-center gap-[25px] rounded-[10px] bg-cover bg-no-repeat' style={{ backgroundImage: `url(${ImageIcons.faqBg})` }}>
                <div className='max-lg:w-[400px] max-md:w-full'>
                  <h2 className='font-medium mb-[10px]'>Get in touch</h2>
                  <p className='lg:text-[25px] md:text-[20px] text-[18px] mb-0'>Head over to our contact page to connect with us today!</p>
                </div>

                <Button hrefLink='/contact-us' title="Contact Us" />
              </div>
            </div>

            <div className="container">
              <div className="mx-auto bg-white  rounded-lg">
                <h2 className='mb-[40px]'>Frequently Asked Questions</h2>
                {serviceDetails?.serviceFaq?.map((faq, index) => (
                  <AccordionItem
                    key={index}
                    active={activeIndex === index}
                    handleToggle={() => handleToggle(index)}
                    faq={faq}
                  />
                ))}
              </div>
            </div>
          </section>
        }
        {(serviceDetails?.overallDescription) &&
          <section className='pt-0'>
            <div className='container'>
              <p className='mb-0'><i>{serviceDetails?.overallDescription}</i></p>
            </div>
          </section>
        }
        {/* <section>
        <div>
          <h2>{serviceDetails?.keyfeature}</h2>
        </div>
        <ul>
          {
            serviceDetails?.keyfeatures?.map((item) => (
              // return (

              <li>{item?.title}</li>

              // )

            ))
          }
        </ul>

      </section> */}


      </Layout>
    </>
  )
}

export default ServicesDetails