import React from 'react'
// import SEO from '../components/shared/seo'
// import InnerPageBanner from '../components/shared/innerPageBanner'
// import ImageIcons from '../components/imageComponent/ImageIcons'
import CitiesDetail from '../components/cities/citiesdetail'

const CityDetails = () => {
  return (
    <div>
      {/* <SEO title={metaTitle} description={metaDescription} keywords={keyword}/> */}
      {/* <SEO
        title="About Us | Skypro"
        description="SkyPro Technologies provides the highest quality services. Empowering companies to transform and innovate through cutting-edge technology solutions."
      /> */}
      {/* <InnerPageBanner title='Cities' innerPageBanner={ImageIcons.aboutBg} /> */}
      <CitiesDetail/>

    </div>
  )
}

export default CityDetails