
import React, { useEffect, useState, useRef, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MenuDrawer from "./drawer";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaXTwitter } from "react-icons/fa6";
import { FaPinterestP } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import { IoCloseSharp } from "react-icons/io5";
import { windowScroll } from "../utils/windowScroll";
import { MdCall, MdSearchOff, MdEmail } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { FiLogIn } from "react-icons/fi";
import { FaUser } from "react-icons/fa";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import ImageIcons from '../imageComponent/ImageIcons';
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import axios from 'axios';
import { VscAccount } from "react-icons/vsc";
import servicesData from '../utils/data/service.json'
import { TbSocial } from "react-icons/tb";
import { IoSearch } from "react-icons/io5";
import { UIContext } from '../../utils/context';
import { ToastContainer } from 'react-toastify';
import ScrollToTopButton from "../utils/bottomToTopScroll";
import { MdContactPage } from "react-icons/md";

const Layout = ({ children, title = "" }) => {
  const navigate = useNavigate()
  // const [scrollPosition, setScrollPosition] = useState(false);
  const [isSticky, setSticky] = useState(false);
  const [show, setShow] = useState(true)
  const handleFloatingAction = () => {
    setShow(!show)
  }
  const currentYear = new Date().getFullYear();

  const pathname = useLocation().pathname;
  console.log("pathname>>>>>>>>>>>>>>", pathname)

  const storedUser = JSON.parse(localStorage.getItem('user'));

  console.log("storedUser", storedUser)
  const id = storedUser?._id
  // const isActive = storedUser?.isActivecustomer

  console.log("id", id)

  const [showLogoutModal, setShowLogoutModal] = useState(false); // State for logout confirmation modal
  const [disabled, setDisabled] = useState(false);
  console.log("setDisabled", setDisabled)

  const handleSub = (event) => {
    setShowLogoutModal(false);
    event.preventDefault();

    // Remove item from localStorage
    // localStorage.removeItem('user');
    localStorage.clear()
    navigate('/login')
    window.location.reload()

    // Optionally, you can perform other actions here after removing the item
  }
  const handle = () => {

    setShowLogoutModal(true);
  }

  // const windowScrollToTop = () => {
  //   const offset = window.scrollY;
  //   if (offset > 400) {
  //     setScrollPosition(true);
  //   } else {
  //     setScrollPosition(false)
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener('scroll', windowScrollToTop);
  //   return () => {
  //     window.removeEventListener('scroll', windowScrollToTop);
  //   };
  // }, []);

  // Header On Scroll
  const handleScroll = () => {
    const offset = window.scrollY;
    setSticky(offset > 300);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [email, setEmail] = useState("")
  const [successMsg, setSuccessMsg] = useState(false)
  const [error, setError] = useState("")
  const [data1, setData1] = useState("")
  console.log("data1===>>>>>", data1)


  const fetchData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL}`);
      setData1(response?.data?.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    // setError(''); // Clear the error message when the user starts typing
    // const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    //   if (!email.trim()) {
    //     setError("Please enter your email");
    //     return;
    //   }
    //   if (!emailPattern.test(email)) {
    //     setError("Please enter a valid email address");
    //     return;
    //   }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email.trim()) {
      setError("Please enter your email");
      // setEmail("")
      return;
    }
    else if (!emailPattern.test(email)) {
      setError("Please enter a valid email address");
      // setEmail("")
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}user/addsubscriber`, {
        email: email,

      });

      console.log(response.data.message);
      setEmail('')
      setSuccessMsg(true)
      setTimeout(() => {
        setSuccessMsg(false)
      }, 2000);
    } catch (error) {
      console.error('Error submitting form:', error);

    }
  };

  // const customer = "/customer";

  const [searchTerm, setSearchKey] = useState("");
  const { setSearchTerm, setSearchedData } = useContext(UIContext);
  const [filteredBlogs, setFilteredBlogs] = useState([]);
  const [filteredServices, setFilteredServices] = useState([]);
  const [blogData, setBlogData] = useState([]);

  console.log("filteredBlogs", filteredBlogs)
  console.log("filteredServices", filteredServices)




  const fetchBlogs = async () => {
    const response = await axios.get(`${process.env.REACT_APP_URL}user/getblog`);
    console.log("search", response.data.data)
    setBlogData(response.data.data)
  }


  useEffect(() => {
    fetchBlogs()
  }, [])


  useEffect(() => {
    const filteredResult =
      Array.isArray(blogData) && blogData.length > 0
        ? blogData.filter((blogItem) =>
          blogItem.isActive === true &&
          (blogItem.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            blogItem.description?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            blogItem.author?.toLowerCase().includes(searchTerm.toLowerCase()))
        )
        : [];
    setFilteredBlogs(filteredResult);
  }, [searchTerm, blogData]);




  useEffect(() => {
    const filteredServiceResult =
      Array.isArray(servicesData) && servicesData.length > 0
        ? servicesData.filter((serviceItem) => {
          const searchTermLower = searchTerm.toLowerCase();
          const additionalInfoMatches = serviceItem?.additionalInfo?.some(
            (info) =>
              info.title?.toLowerCase().includes(searchTermLower) ||
              info.description?.toLowerCase().includes(searchTermLower)
          );
          const slowFeaturesMatches = serviceItem?.features?.some(
            (feature) =>
              feature.title?.toLowerCase().includes(searchTermLower) ||
              feature.description?.toLowerCase().includes(searchTermLower)
          );
          const fastFeaturesMatches = serviceItem?.plans?.some(
            (feature) =>
              feature?.title?.toLowerCase().includes(searchTermLower) ||
              feature?.description?.toLowerCase().includes(searchTermLower)
          );
          return (
            serviceItem?.heading?.toLowerCase().includes(searchTermLower) ||
            serviceItem?.type?.toLowerCase().includes(searchTermLower) ||
            serviceItem?.title?.toLowerCase().includes(searchTermLower) ||
            serviceItem?.description?.toLowerCase().includes(searchTermLower) ||
            serviceItem?.headingdescription?.toLowerCase().includes(searchTermLower) ||
            additionalInfoMatches ||
            slowFeaturesMatches ||
            fastFeaturesMatches
          );
        })
        : [];
    setFilteredServices(filteredServiceResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, servicesData]);


  const searchDropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchDropdownRef.current && !searchDropdownRef.current.contains(event.target)) {
        setSearchKey('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [searchDropdownRef]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      setSearchedData({ blogs: filteredBlogs, services: filteredServices });
      setSearchTerm(searchTerm);
      setFilteredServices([]);
      console.log("Searched term:", searchTerm);
      setSearchKey("");
      navigate("/search", {
        state: {
          searchTerm: searchTerm
        }
      });
    }
  };

  const handleSearchSubmit = () => {
    if (!searchTerm.trim()) {
      // Do not perform search if searchTerm is empty
      return;
    }
    setSearchedData({ blogs: filteredBlogs, services: filteredServices });
    setSearchTerm(searchTerm);
    setFilteredServices([]);
    console.log("Searched term:", searchTerm);
    setSearchKey("");
    navigate("/search", {
      state: {
        searchTerm: searchTerm
      }
    });
  };
  function resetHandler() {
    setSearchKey("");
    setFilteredServices([]);
  }

  // const handleCopyText = () => {
  //   const tempInput = document.createElement('input');
  //   document.body.appendChild(tempInput);
  //   tempInput.value = (data.mail);
  //   tempInput.select();
  //   document.execCommand('copy');
  //   document.body.removeChild(tempInput);

  //   toast.success('Copied to Clipboard');
  // };

  return (
    <>
      <header className="relative z-50 ">
        <div className="bg-black 2xl:py-[15px] py-[10px] relative">
          <div className="container">
            <div className="flex max-md:flex-col justify-between items-center gap-[15px]">
              <div>
                <ul className="flex gap-[20px]">
                  <li>
                    <Link className="text-white" to={`tel:${data.mobileNo}`}><MdCall className="inline-block text-[22px] mr-[2px]" />{data.mobileNo}</Link>
                  </li>
                  <li>
                    <Link to="/contact-us">
                      <div
                        // onClick={handleCopyText}
                        className="text-white cursor-pointer flex items-center gap-[5px]"><MdContactPage className="inline-block text-[22px]" />
                        Contact Us
                        {/* <IoMdCopy className="inline-block text-[19px]" /> */}
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="relative rounded-[3px] max-md:w-full" ref={searchDropdownRef}>
                <input
                  value={searchTerm}
                  type="search"
                  placeholder="Search...."
                  onChange={(e) => setSearchKey(e.target.value)}
                  onKeyDown={handleKeyPress}
                  className="h-[42px] pl-[12px] pr-[50px] focus:outline-none lg:w-[320px] max-md:w-full rounded-[2px]" />

                {searchTerm && (
                  <div className='absolute top-full mt-[1px] left-0 w-full rounded shadow-[0_5px_10px_5px_rgba(0,0,0,0.2)] bg-white z-[999999]'>
                    {filteredBlogs.length === 0 && filteredServices.length === 0 ? (
                      <ul>
                        <li>
                          <div className='text-[#fd5901] p-[10px] flex items-center'>
                            <MdSearchOff className='h-5 w-6 cursor-pointer inline-block' />
                            Not Found
                          </div>
                        </li>
                      </ul>
                    ) : (
                      <ul>
                        {filteredBlogs?.map((blogItem) => (
                          <li key={blogItem?._id} className="text-white border-t first:border-hidden">
                            <Link
                              onClick={resetHandler}
                              className='py-[10px] px-[15px] inline-block w-full text-[#000]'
                              to={`/blogs/${blogItem?.handle?.replace(/\s+/g, "-").toLowerCase()}`}
                            >
                              {blogItem?.title.slice(0, 30)}.....
                            </Link>
                          </li>
                        ))}
                        {filteredServices?.map((serviceItem) => (
                          <li key={serviceItem?._id} className="text-white border-t first:border-hidden">
                            <Link
                              onClick={resetHandler}
                              className='py-[10px] px-[15px] inline-block w-full text-[#000]'
                              to={`/service/${serviceItem?.type?.replace(/\s+/g, "-").toLowerCase()}`}
                            >
                              {serviceItem?.title.slice(0, 30)}...
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                )}
                <button type="button" className="h-[42px] w-[42px] bg-[#fd5901] text-white absolute top-0 right-0 flex justify-center items-center rounded-r-[2px]">
                  <IoSearch className="text-[22px]" onClick={handleSearchSubmit} /></button>
              </div>
            </div>
          </div>
        </div>
        <div className={` bg-[#141416] ${isSticky ? 'header-position' : ''}`} >
          <div className="container">
            <div className="flex justify-between items-center lg:h-auto">
              <Link to="/" className="flex items-center mr-[10px] py-[12px]"
                onClick={() => { window.location.pathname = "/" }}
              >
                <img
                  src={ImageIcons.logo}
                  className="min-w-[120px] 2xl:max-h-[82px] xl:max-h-[80px] max-h-[70px]"
                  alt='Logo'
                />
              </Link>

              <nav className='lg:block hidden'>
                <ul className=" flex items-center lg:flex-nowrap flex-wrap lg:justify-center justify-start justify-left">
                  {data?.navLinks.map((link, i) => (
                    <li key={i} className="relative group lg:w-auto w-full whitespace-nowrap lg:py-[40px] py-[10px] xl:px-[25px] px-[15px]">
                      {!link?.handle ?
                        <div className={`text-[#fff] hover:text-[#f48533] 2xl:text-[18px] xl:text-[17px] lg:inline-block block w-full  ${pathname === link.handle && "!text-[#f48533]"}`}
                        // onClick={() => { window.location.pathname = link.handle }}
                        >
                          {link.title}
                        </div>
                        :
                        <Link to={link.handle} className={`text-[#fff] hover:text-[#f48533] 2xl:text-[18px] xl:text-[17px] lg:inline-block block w-full  ${pathname === link.handle && "!text-[#f48533]"}`}
                          onClick={() => { window.location.pathname = link.handle }}
                        >
                          {link.title}
                        </Link>


                      }

                      {link?.subLinks && (
                        <ul className="duration-500 left-0 shadow-[0px_0px_15px_0px_rgba(0,0,0,0.2)] group-hover:visible group-hover:h-auto group-hover:overflow-visible grid bg-white text-black md:absolute relative top-[100%] md:w-[240px] w-[100%] z-[1111] invisible overflow-hidden h-0">
                          {link?.subLinks?.map((item, i) => (
                            <li key={i} className="w-fill">
                              {item?.type &&
                                <Link to={`/service/${item?.type}`} className={`border-b w-full inline-block	border-[#3c62e921] text-[16px] hover:bg-[#f48533] hover:text-white px-[20px] py-[10px]  ${pathname === `/service/${item?.type}` && " text-[#f48533]"} `}
                                  onClick={() => { window.location.pathname = `service/${item?.type}` }}
                                >{item?.title}</Link>
                              }
                              {item?.handle &&
                                <Link to={`/pricing/${item?.handle}`} className={`border-b w-full inline-block	border-[#3c62e921] text-[16px] hover:bg-[#f48533] hover:text-white px-[20px] py-[10px]  ${pathname === `/pricing/${item?.handle}` && " text-[#ffffff] bg-[#f48533]"} `}
                                  onClick={() => { window.location.pathname = `pricing/${item?.handle}` }}
                                >{item?.title}</Link>
                              }
                              {/* {item?.handle === "broadband" &&
                                <Link to={`/pricing/broadband`} className={`border-b w-full inline-block	border-[#3c62e921] text-[16px] hover:bg-[#f48533] hover:text-white px-[20px] py-[10px]  ${pathname === "pricing/broadband" && " !text-[#f48533]"} `}
                                  onClick={() => { window.location.pathname = `/pricing/broadband` }}
                                >{item?.title}</Link>
                              } */}

                            </li>

                          ))}
                        </ul>
                      )}
                    </li>
                  ))}

                  {/* <li className="relative group lg:w-auto w-full whitespace-nowrap xl:pr-[25px] pr-[15px]">
                  <Link to='#' className={`text-[#fff] px-[20px] py-[15px] rounded-[10px] bg-[#f48533] 2xl:text-[18px] xl:text-[17px] lg:inline-block block w-full`}
                  >Get New Conncetion</Link>
                </li>

                <li className="relative group lg:w-auto w-full whitespace-nowrap ">
                  <Link to='#' className={`text-[#fff] px-[20px] py-[15px] rounded-[10px] bg-[#f48533] 2xl:text-[18px] xl:text-[17px] lg:inline-block block w-full`}
                  >Pay Bill / Recharge</Link>
                </li> */}

                  <li className="relative group lg:w-auto w-full whitespace-nowrap lg:py-[40px] py-[10px] xl:px-[25px] px-[15px]">
                    <Link to='' className={`text-[#fff] hover:text-[#f48533] text-[20px] lg:inline-block block w-full`}
                    ><VscAccount className="inline-block mr-1 text-[22px]" /> Account</Link>

                    <ul className="duration-500 left-0 shadow-[0px_0px_15px_0px_rgba(0,0,0,0.2)] group-hover:visible group-hover:h-auto group-hover:overflow-visible grid bg-white text-black md:absolute relative top-[100%] md:w-[240px] w-[100%] z-[1111] invisible overflow-hidden h-0">
                      <li className="w-fill">
                        <Link to='/get-myinternet' onClick={windowScroll} className='border-b w-full inline-block	border-[#3c62e921] text-[16px] hover:bg-[#f48533] hover:text-white px-[20px] py-[10px]'>Get New Connection</Link>
                      </li>
                      <li className="w-fill">
                        <Link to='/contact-us' onClick={windowScroll} className='border-b w-full inline-block	border-[#3c62e921] text-[16px] hover:bg-[#f48533] hover:text-white px-[20px] py-[10px]'>Pay Bill / Recharge</Link>
                      </li>
                      {!id ?
                        <>
                          {/* <li className="w-fill">
                            <Link to='/register' className='border-b w-full inline-block	border-[#3c62e921] text-[16px] hover:bg-[#f48533] hover:text-white px-[20px] py-[10px]'>Register</Link>
                          </li>  */}

                          <li className="w-fill">
                            <Link to='https://myaccount.myinternetzone.com/' target="_blank" className='border-b w-full inline-block	border-[#3c62e921] text-[16px] hover:bg-[#f48533] hover:text-white px-[20px] py-[10px]'>Login</Link>
                          </li>
                        </>
                        :
                        null
                      }

                      {id ? (
                        <>
                          <li className="w-fill">
                            <Link to='/customer' className='border-b w-full inline-block	border-[#3c62e921] text-[16px] hover:bg-[#f48533] hover:text-white px-[20px] py-[10px]'>Customer Portal</Link>
                          </li>
                          <li>
                            <button type="button" onClick={() => handle()} className='border-b w-full inline-block text-left border-[#3c62e921] text-[16px] hover:bg-[#f48533] hover:text-white px-[20px] py-[10px]'>Logout</button>
                          </li>
                        </>
                      ) : null}

                    </ul>

                  </li>

                  <Link to="https://play.google.com/store/apps/details?id=app.customerportal.digitax" target="_blank" className="ml-[10px] text-[#000] bg-[#fff] hover:text-white hover:bg-[#f48533] text-[20px] font-medium rounded-l-[10px] rounded-r-[10px] px-[20px] py-[13px]">Get App <IoLogoGooglePlaystore className="inline-block text-[22px]" /></Link>
                </ul>
              </nav>
              <div className="inline-block lg:hidden">
                <MenuDrawer navLinks={data.navLinks} />
              </div>
            </div>
          </div>
        </div>
      </header>
      {children}
      <footer className="bg-[#141416] bg-center bg-contain bg-no-repeat" style={{ backgroundImage: `url(${ImageIcons.footerBg})` }}>
        <div className="py-[50px] bg-[linear-gradient(0deg,#f96615_0%,#f68749_100%)]">
          <div className="container relative">
            <div className="absolute left-[15px] top-[50%] -translate-y-2/4 md:w-[150px] sm:w-[100px] w-[70px]">
              <img src={ImageIcons.mailIcon} alt="mail icon" />
            </div>
            <div className="absolute right-[15px] top-[50%] -translate-y-2/4 md:w-[150px] sm:w-[100px] w-[70px]">
              <img src={ImageIcons.sendIcon} alt="send icon" />
            </div>
            <div className="grid lg:grid-cols-2 grid-cols-1 gap-[30px] items-center md:px-[150px] px-0">
              <div className="lg:text-left text-center">
                <h3 className="text-white xl:text-[28px] text-[24px]">For Joint Venture, Query or Internet Business, please write or call</h3>
                <div className="inline-flex flex-col xl:flex-row xl:gap-[20px] gap-[15px]">
                  <Link to={`tel:${data.mobileNo}`} className="inline-flex items-center lg:m-0 m-auto gap-[10px] text-[16px] text-[#fff] hover:text-[#141416] duration-500"><MdCall className="text-[30px] min-w-[30px]" /> {data.mobileNo}</Link>
                  <Link to={`mailto:${data.mail}`} className="inline-flex items-center gap-[10px] text-[16px] text-[#fff] hover:text-[#141416] duration-500"> <MdEmail className="text-[30px] min-w-[30px]" /> {data.mail}</Link>
                </div>
              </div>

              {successMsg ? (

                <div className="newsletter-form-info mt-8">
                  <form className="flex pb-5 md:max-w-[550px] ">
                    <div className="w-full relative max-lg:flex max-lg:flex-col bg-black rounded-full">
                      <div
                        className=" bg-[#000] text-white rounded-full block w-full p-4 max-lg:mb-4 ">
                        <p className="text-[#FFF] text-center w-full mb-0">Thank you! Your message has been sent.</p>
                      </div>
                      {/* <button className="lg:absolute lg:right-0 lg:top-0 lg:ml-2 rounded-full bg-[#000] text-[#fff] hover:bg-[#F1F8E8] hover:text-[#000] block py-4 px-7 duration-[400ms,700ms]">Subscribe Now</button> */}
                    </div>
                  </form>
                </div>
              )
                :
                (

                  <form onSubmit={handleSubmit}>
                    <div className="relative rounded-[10px] overflow-hidden">
                      <input
                        disabled={disabled}
                        name="email"
                        id="email"
                        value={email}
                        onChange={handleEmailChange}
                        placeholder="Your E-mail"
                        className="w-full h-[50px] px-[15px] focus:outline-none pr-[120px]"
                      />
                      <button className="absolute right-0 top-0 h-[50px] text-[#fff] bg-[#141416] hover:bg-[#000] hover:text-[#fff] px-[30px] py-[10px] duration-500" >Submit</button>
                    </div>
                    {error && <p className="text-white">{error}</p>}
                  </form>
                )
              }
            </div>
          </div>
        </div>
        <div className="container">
          <div className="flex max-xl:flex-wrap gap-[40px] md:py-[50px] py-[30px]">
            <div className="xl:w-[25%] w-full">
              <Link className="inline-block mb-[15px]" to='/'><img onClick={windowScroll} className="w-[150px]" src={ImageIcons.logo} alt="Logo"></img></Link>
              <p className="text-[#D7D7D7] mb-[10px]">We, at My Internet are focused towards offering our users with high-speed, reliable and affordable internet services via a wide range of plans customised to your needs. With us, you will enjoy seamless streaming, uploading, downloading and much more.</p>
              <div className="mt-[10px]">
                <Link to='https://cloud.jazenetworks.com/signin' target="_blank" className='text-[16px] xl:w-[290px] sm:w-[190px] w-full text-[#fff] hover:text-[#fff] rounded-[10px] border border-[#D7D7D7] hover:border-[#F48533] hover:bg-[#F48533] mt-[10px] p-[12px] inline-flex justify-center items-center sm:mr-[10px]'>Franchisee Login <FiLogIn className="ml-[10px]" /></Link>
                <Link to='https://myaccount.myinternetzone.com/' target="_blank" className='text-[16px] sm:w-[140px] w-full text-[#fff] hover:text-[#fff] rounded-[10px] border border-[#D7D7D7] hover:border-[#F48533] hover:bg-[#F48533] mt-[10px] p-[12px] inline-flex justify-center items-center'>User Login <FaUser className="ml-[10px]" /></Link>
                <Link to='https://play.google.com/store/apps/details?id=app.customerportal.digitax' target="_blank" className='text-[16px] sm:w-[140px] w-full text-[#fff] hover:text-[#fff] rounded-[10px] border border-[#D7D7D7] hover:border-[#F48533] hover:bg-[#F48533] mt-[10px] p-[12px] inline-flex justify-center items-center sm:ml-[10px]'>User App <IoLogoGooglePlaystore className="ml-[10px]" /></Link>
              </div>
            </div>

            <div className="xl:w-[75%] w-full grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 md:gap-[20px] gap-[30px]">
              {footerLinks.map((link, i) => (
                <div className="" key={i}>
                  <h3 className="text-[#F48533] font-medium	pb-[8px] sm:mb-[25px] text-[20px] md:text-[24px] relative before:content before:left-[0] before:bottom-[0] before:absolute before:h-[1.5px] before:w-[80px] before:bg-gradient-to-r before:from-[#F48533]" >{link.title}</h3>
                  <ul className="grid gap-[10px] sm:gap-[12px] md:gap-[15px]">
                    {link.subLinks.map((item, i) => (
                      <li key={i}>
                        {item.handle1 &&
                          <Link to={item.handle1} className={`text-[16px] text-[#cecece] hover:text-[#F48533] flex items-center ${pathname === item.handle1 && "text-[#f48533]"} `} onClick={() => {
                            window.location.pathname = item.handle1;
                            windowScroll();
                          }} >
                            <IoCheckmarkDoneSharp className="mr-[6px] text-[#F48533]" /> {item.link}
                          </Link>
                        }
                        {item?.type &&
                          <Link to={`/service/${item?.type}`} className={`text-[16px] text-[#cecece] hover:text-[#F48533] flex items-center ${pathname === `/service/${item?.type}` && "text-[#f48533]"} `} onClick={() => {
                            window.location.pathname = `service/${item?.type}`;
                            windowScroll();
                          }} >
                            <IoCheckmarkDoneSharp className="mr-[6px] text-[#F48533]" /> {item.link}
                          </Link>
                        }
                        {item?.handle &&
                          <Link to={`/pricing/${item?.handle}`} className={`text-[16px] text-[#cecece] hover:text-[#F48533] flex items-center ${pathname === `/pricing/${item?.handle}` && "text-[#f48533]"} `} onClick={() => {
                            window.location.pathname = `pricing/${item?.handle}`;
                            windowScroll();
                          }} >
                            <IoCheckmarkDoneSharp className="mr-[6px] text-[#F48533]" /> {item.link}
                          </Link>
                        }
                      </li>
                    ))}
                  </ul>
                </div>
              ))}

              <div className="my-[10px] lg:my-[0]">
                <h3 className="text-[#F48533] font-medium	pb-[8px] mb-[25px] text-[20px] md:text-[24px] relative before:content before:left-[0] before:bottom-[0] before:absolute before:h-[1.5px] before:w-[80px] before:bg-gradient-to-r before:from-[#F48533]" onClick={windowScroll}>Contact Info</h3>
                <div className="grid gap-[15px] md:gap-[20px]">
                  <Link to={`tel:${data.mobileNo}`} className="flex gap-[10px] items-center text-[16px] text-[#D7D7D7] hover:text-[#F48533] duration-500"><MdCall className="text-[30px] min-w-[30px]" />{data.mobileNo}</Link>
                  <Link to="/contact-us" onClick={windowScroll}>
                    <div className="flex gap-[10px] items-center text-[16px] text-[#D7D7D7] hover:text-[#F48533] duration-500 cursor-pointer"><MdContactPage className="text-[30px] min-w-[30px]" />
                      Contact Us
                      {/* <IoMdCopy className="inline-block text-[19px]" /> */}
                    </div>
                  </Link>

                  <div className="flex gap-[10px] items-start">
                    <FaLocationDot className="text-[#cecece] text-[30px] min-w-[30px]" />
                    <span className="text-[16px] text-[#D7D7D7]">{data.address}</span>
                  </div>
                  <div className="flex gap-[10px] items-start">
                    <FaLocationDot className="text-[#cecece] text-[30px] min-w-[30px]" />
                    <span className="text-[16px] text-[#D7D7D7]">{data.address1}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-[#000]">
          <div className="container">
            <div className="flex md:justify-between justify-center items-center md:flex-nowrap flex-wrap py-[20px] gap-[10px]">
              <p className="text-[16px] text-[#cecece] mb-0 md:text-left text-center">© Copyright {currentYear} MyInternet. All Right Reserved</p>
              <div className="md:text-left text-center md:w-auto w-full">
                <Link to='/privacy-policy' className={`text-[#cecece] hover:text-[#F48533] lg:mx-[20px] mx-[10px] my-[px] inline-block  ${pathname === `/privacy-policy` && "text-[#f48533]"} `} onClick={() => {
                  window.location.pathname = '/privacy-policy';
                  windowScroll()
                }}>Privacy Policy</Link>
                <Link className={`text-[#cecece] hover:text-[#F48533] lg:mx-[20px] mx-[10px] my-[8px] inline-block  ${pathname === `/terms-conditions` && "text-[#f48533]"} `} onClick={() => {
                  window.location.pathname = '/terms-conditions';
                  windowScroll()
                }} to='/terms-conditions'>Terms & Conditions</Link>
                <Link className={`text-[#cecece] hover:text-[#F48533] lg:mx-[20px] mx-[10px] my-[8px] inline-block  ${pathname === `/refund-policy` && "text-[#f48533]"} `} onClick={() => {
                  window.location.pathname = '/refund-policy';
                  windowScroll()
                }} to='/refund-policy'>Refund Policy</Link>
                <Link className={`text-[#cecece] hover:text-[#F48533] lg:mx-[20px] mx-[10px] my-[8px] inline-block  ${pathname === `/disclaimer` && "text-[#f48533]"} `} onClick={() => {
                  window.location.pathname = '/disclaimer';
                  windowScroll()
                }} to='/disclaimer'>Disclaimer</Link>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* <button className={`' group flex justify-center items-center 2xl:right-[50px] md:right-[30px] duration-500 right-[15px] bg-[#fff] hover:bg-[#F48533] w-[50px] h-[50px] shadow-md shadow-[#a4cae3] rounded-[10px] z-50 ' ${scrollPosition ? 'opacity-100 overflow-visible fixed transition-[0.8s] bottom-[30px]' : 'bottom-[102%]'}`}
        onClick={() => {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }}>
        <IoArrowUpSharp className="text-[#000] text-[22px] group-hover:text-[#fff]" />
      </button> */}

      <ScrollToTopButton />
      {(pathname === "/get-myinternet" || pathname === "/thanks"  ) ?
        null
        :
        <div className="fixed top-[50%] right-0 z-50 -translate-y-2/4">
          <div className="cursor-pointer rounded-full" onClick={handleFloatingAction}>
            {show ? (
              <IoCloseSharp title="Close Social media" className="h-[40px] w-[40px] bg-[#fd5901] rounded-full text-white p-[7px] md:text-[22px] text-[20px] leading-[18px] flex justify-center items-center" />
            ) : (
              <TbSocial title="Social media" className="animate-bounce h-[40px] w-[40px] bg-[#fd5901] rounded-full text-white p-[7px] md:text-[22px] text-[20px] leading-[18px] flex justify-center items-center" />
            )}
          </div>
          {show &&
            <>
              <Link to='https://www.facebook.com/myinternetMohali/' target="_blank" className='text-[18px] my-[3px] text-[#fff] bg-[#3d5a98] h-[40px] w-[40px] flex justify-center items-center relative group'><FaFacebookF /> <span className="px-[12px] bg-[#3d5a98] h-[40px] leading-[40px] duration-500 origin-[right_center] overflow-hidden transform scale-x-0 invisible opacity-0 visibil group-hover:scale-x-100 group-hover:visible group-hover:opacity-100 absolute right-[41px]">Facebook</span></Link>
              <Link to='https://www.instagram.com/myinternet__/' target="_blank" className='text-[18px] my-[3px] text-[#fff] bg-gradient-to-br from-yellow-300 via-red-400 to-purple-600 h-[40px] w-[40px] flex justify-center items-center group'><FaInstagram /> <span className="px-[12px] bg-gradient-to-br from-yellow-300 via-red-400 to-purple-600 h-[40px] leading-[40px] duration-500 origin-[right_center] overflow-hidden transform scale-x-0 invisible opacity-0 visibil group-hover:scale-x-100 group-hover:visible group-hover:opacity-100 absolute right-[41px]">Instagram</span></Link>
              <Link to='https://twitter.com/MyInternet_' target="_blank" className='text-[18px] my-[3px] text-[#fff] bg-[#0f1419] h-[40px] w-[40px] flex justify-center items-center group'><FaXTwitter /> <span className="px-[12px] bg-[#0f1419] h-[40px] leading-[40px] duration-500 origin-[right_center] overflow-hidden transform scale-x-0 invisible opacity-0 visibil group-hover:scale-x-100 group-hover:visible group-hover:opacity-100 absolute right-[41px]">Twitter</span></Link>
              <Link to='https://www.youtube.com/@MyInternet_' target="_blank" className='text-[18px] my-[3px] text-[#fff] bg-[#ff0000] h-[40px] w-[40px] flex justify-center items-center group'><FaYoutube /> <span className="px-[12px] bg-[#ff0000] h-[40px] leading-[40px] duration-500 origin-[right_center] overflow-hidden transform scale-x-0 invisible opacity-0 visibil group-hover:scale-x-100 group-hover:visible group-hover:opacity-100 absolute right-[41px]">Youtube</span></Link>
              <Link to='https://www.linkedin.com/company/my-internet/' target="_blank" className='text-[18px] my-[3px] text-[#fff] bg-[#0077b5] h-[40px] w-[40px] flex justify-center items-center group'><FaLinkedinIn /> <span className="px-[12px] bg-[#0077b5] h-[40px] leading-[40px] duration-500 origin-[right_center] overflow-hidden transform scale-x-0 invisible opacity-0 visibil group-hover:scale-x-100 group-hover:visible group-hover:opacity-100 absolute right-[41px]">Linkedin</span></Link>
              <Link to='https://www.pinterest.com/myinternet_/' target="_blank" className='text-[18px] my-[3px] text-[#fff] bg-[#e60023] h-[40px] w-[40px] flex justify-center items-center group'><FaPinterestP /> <span className="px-[12px] bg-[#e60023] h-[40px] leading-[40px] duration-500 origin-[right_center] overflow-hidden transform scale-x-0 invisible opacity-0 visibil group-hover:scale-x-100 group-hover:visible group-hover:opacity-100 absolute right-[41px]">Pinterest</span></Link></>
          }
        </div>
      }

      {/* <div className="fixed top-[50%] left-0 z-50 -translate-y-2/4"> */}

      {/* <Link to='' className='text-[18px] my-[3px] text-[#fff] bg-[#3d5a98] h-[60px] w-[60px] flex justify-center items-center relative group'><MdOutlineRouter className="text-[40px]" />
          <span className="px-[12px] leading-[20px] text-[15px] flex items-center bg-[#3d5a98] h-[60px] duration-500 origin-[left_center] overflow-hidden transform scale-x-0 invisible opacity-0 visibil group-hover:scale-x-100 group-hover:visible group-hover:opacity-100 absolute left-[61px]">New<br></br> Connection</span>
        </Link> */}

      {/* <Link to='' className='flex-col text-[12px] rounded-tr-[5px] text-center leading-[15px] p-2.5 my-[2px] border-transparent text-[#fff] bg-[linear-gradient(157deg,#f48533_0%,#3308d3_100%)] flex justify-center items-center relative group w-[80px] hover:w-[90px]'>
          <TbRouter className="text-[28px] mb-[5px]" />
          New<br></br> Connection
        </Link>
        <Link to='' className='flex-col text-[12px] text-center leading-[15px] p-2.5 my-[2px] border-transparent text-[#fff] bg-[linear-gradient(157deg,#f48533_0%,#3308d3_100%)] flex justify-center items-center relative group w-[80px] hover:w-[90px]'>
          <MdPayment className="text-[28px] mb-[5px]" />
          Quick Pay
        </Link>
        <Link to='' className='flex-col text-[12px] rounded-br-[5px] text-center leading-[15px] p-2.5 my-[2px] border-transparent text-[#fff] bg-[linear-gradient(157deg,#f48533_0%,#3308d3_100%)] flex justify-center items-center relative group w-[80px] hover:w-[90px]'>
          <GrMap className="text-[28px] mb-[5px]" />
          Check<br></br> Feasibility
        </Link>
      </div> */}

      <ToastContainer position="bottom-right" autoClose={1000} hideProgressBar={true} />

      {showLogoutModal && (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <p className="mb-4">Are you sure you want to logout?</p>
            <div className="flex justify-center">
              <button className="bg-red-500 text-white px-4 py-2 mr-4 rounded-md" onClick={(e) => handleSub(e)}>Yes</button>
              <button className="bg-gray-300 text-gray-800 px-4 py-2 rounded-md" onClick={() => setShowLogoutModal(false)}>No</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const data = {
  mobileNo: "+91 9464001234",
  mail: "info@myinternet.services",
  address: "Plot No. 205, Near Markanda Complex, Opp. Punjabi University, Patiala | Punjab 147002",
  address1: "B-70, Phase 7, Industrial Area, Sector 74, Sahibzada Ajit Singh Nagar, Punjab 160055",
  navLinks: [
    {
      title: "Services",
      // handle: "/services-details",
      subLinks: [
        {
          title: "Internet Leased Line",
          type: "internet-leased-line"
        },
        {
          title: "Business Internet Providers",
          type: "business-internet-providers"
        },
        {
          title: "Home Internet Providers",
          type: "home-internet-providers"
        },
        {
          title: "IPTV",
          type: "iptv"
        },
        {
          title: "Free Wifi Hotspots",
          type: "free-wifi-hotspots"
        },
        {
          title: "Managed Network",
          type: "managed-network"
        },
        {
          title: "Cloud Cam",
          type: "cloud-cam"
        },
        {
          title: "VoIP Intercom",
          type: "VoIP-intercom"
        },
        {
          title: "CDN Services",
          type: "cdn-services"
        },

      ]
    },
    {
      title: "Pricing",
      handle: "/pricing",
      subLinks: [
        {
          title: "Broadband",
          handle: "broadband"
        },
        {
          title: "Custom Plan",
          handle: "custom-plan"
        },
        {
          title: "OTT",
          handle: "ott"
        },
        {
          title: "Entertainment Combo",
          handle: "entertainment-combo"
        },
        {
          title: "Business Internet",
          handle: "business-internet"
        },
        {
          title: "Home Internet",
          handle: "home-internet"
        },
      ]
    },
    {
      title: "Franchisee",
      handle: "/franchisee"
    },
    {
      title: "About Us",
      handle: "/about-us"
    },

    // {
    //   title: "Contact us",
    //   handle: "#"
    // },
    // {
    //   title: "Career",
    //   handle: "#"
    // },
    // {
    //   title: "Media",
    //   handle: "#"
    // },
  ]
}

const footerLinks = [
  {
    title: "Useful Links",
    subLinks: [
      {
        link: "Vision",
        handle1: "/vision"
      },
      {
        link: "Blogs",
        handle1: "/blogs"
      },
      {
        link: "Career",
        handle1: "/career"
      },
      {
        link: "About Us",
        handle1: "/about-us"
      },
      {
        link: "Contact Us",
        handle1: "/contact-us"
      },
      {
        link: "FAQs",
        handle1: "/faqs"
      },
      {
        link: "Cities",
        handle1: "/cities"
      },
      {
        link: "Get MyInternet",
        handle1: "/get-myinternet"
      },
      {
        link: "Get Leased Line",
        handle1: "/get-leasedline"
      },
      {
        link: "Sitemap",
        handle1: "/sitemap"
      },
    ]
  },
  {
    title: "Services",
    subLinks: [
      {
        link: "Internet Leased Line",
        type: "internet-leased-line"
      },
      {
        link: "Business Internet Providers",
        type: "business-internet-providers"
      },
      {
        link: "Home Internet Providers",
        type: "home-internet-providers"
      },
      {
        link: "IPTV",
        type: "iptv"
      },
      {
        link: "Free Wifi Hotspots",
        type: "free-wifi-hotspots"
      },
      {
        link: "Managed Network",
        type: "managed-network"
      },
      {
        link: "Cloud Cam",
        type: "cloud-cam"
      },
      {
        link: "VoIP Intercom",
        type: "VoIP-intercom"
      },
      {
        link: "CDN Services",
        type: "cdn-services"
      },

    ]
  },
  {
    title: "Pricing",
    subLinks: [
      {
        link: "Broadband",
        handle: "broadband"
      },
      {
        link: "Custom Plan",
        handle: "custom-plan"
      },
      {
        link: "OTT",
        handle: "ott"
      },
      {
        link: "Entertainment Combo",
        handle: "entertainment-combo"
      },
      {
        link: "Business Internet",
        handle: "business-internet"
      },
      {
        link: "Home Internet",
        handle: "home-internet"
      },
    ]
  },

];

export default Layout;
