import React from 'react'
import Layout from '../components/shared/layout'
import InnerPageBanner from '../components/shared/innerPageBanner'
import ImageIcons from '../components/imageComponent/ImageIcons'
import { IoCheckmarkDoneSharp } from 'react-icons/io5'
import SEO from '../components/shared/seo'

const TermsConditions = () => {
  return (
    <>
    <SEO title='Terms & Conditions | My Internet Services' description='MyInternetServices terms and conditions. Learn about our policies, usage guidelines, and legal terms for using our services. Visit now!'/>
    <Layout>
      <InnerPageBanner title='Terms & Conditions' innerPageBanner={ImageIcons.TermCondition} />
      <section>
        <div className='container'>
          <p>Welcome to my Internet, your premier choice for internet services. Before you take our services and join us, it is important for you to understand our terms and conditions in order to have a hassle free experience.</p>

          <h4>Acceptance of Terms</h4>
          <p>If you use my Internet’s services, it means you are in agreement to our terms and conditions. If you have any doubt regarding our services, contact us any time as your support team is available round the clock.</p>

          <h4>Service Provided</h4>
          <p>We provide our users with a variety of internet plans in order to fulfil your needs. We provide our customers with high-speed, reliable and consistent services along with providing dedicated customer support. To get more information about plans and packages, visit our website and check our offers.</p>


          <h4>User Responsibilities</h4>
          <p>In order to provide you with safe and enjoyable space-</p>
          <ul className='mb-[20px]'>
            <li className='mb-[6px] text-[16px] relative pl-[25px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />You have to ensure legal usage of services, there should not be any illegal use.</li>
            <li className='mb-[6px] text-[16px] relative pl-[25px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />You need to respect others, which means you should not get involved in spamming, hacking, or any kind of harassment.</li>
            <li className='mb-[6px] text-[16px] relative pl-[25px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />You should also keep your information secure like protecting your passwords and other personal details.</li>
          </ul>

          <h4>Payment and Billing</h4>
          <p><i>We are committed to provide you with seamless payment process</i></p>
          <h5>Billing Cycle</h5>
          <p>You are charged according to the subscription taken. If you have chosen a monthly plan, you will receive a bill on a monthly basis and an invoice will be sent via email.</p>

          <h5>Payment Methods</h5>
          <p>You can pay via credit/debit card, bank transfer and other online payment methods.</p>

          <h5>Late Payments</h5>
          <p>If you are late on a payment, you will be charged a small fee. However, if the problem continues, it may result in the suspension of the services.</p>

          <h4>Service Availability and Interruptions</h4>
          <p>My Internet team is committed to provide our customers with uninterrupted services, however there are times when we need to perform maintenance. Although we do it during off-peak hours, we ensure notifying you in advance about the same. If there is any unexpected outage, our team is available 24/7 to restore the services.</p>


          <h4>Changes to Terms</h4>
          <p>The terms and conditions can be updated any time and you will be informed about the same. If you continue using our services, you will be considered in agreement with them.</p>


          <h4>Termination of Services</h4>
          <p>You are allowed to cancel your services anytime by contacting our customer support team. If you are facing any issues with our services, feel free to contact us, as we are always there to solve your problems.</p>


          <h4>Limitation of Liability</h4>
          <p>Although we are always devoted to provide uninterrupted and best services, we are not liable to any-</p>
          <ul className='mb-[20px]'>
            <li className='mb-[6px] text-[16px] relative pl-[25px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />Incidental, indirect or consequential damage.</li>
            <li className='mb-[6px] text-[16px] relative pl-[25px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />Of the service interruptions which are beyond our control.</li>
          </ul>
          <p>For more information, feel free to contact us.</p>
        </div>
      </section>
    </Layout>
    </>
  )
}

export default TermsConditions