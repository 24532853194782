import React, { useEffect } from 'react';
import Layout from '../components/shared/layout';
import Hero from "../components/home/hero";
// import BannerBoxs from '../components/home/bannerBoxs';
import DreamInternet from '../components/home/dreamInternet';
import Counting from '../components/home/counting';
import Services from '../components/home/services';
import Testimonial from '../components/home/testimonial';
import Investors from '../components/home/investors';
// import Expansion from '../components/home/expansion';
import Plans from '../components/home/plans';
import axios from "axios"
import FAQ from '../components/home/faq';
import Blogs from '../components/home/blogs';
import WeAreLocated from '../components/home/weAreLocated';
import SEO from '../components/shared/seo';

const Home = () => {

  useEffect(() => {
    async function testing() {
      try {
        const response = await axios.get(`${process.env.REACT_APP_URL}test`);
        console.log("response", response)

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    testing()
  }, [])
  return (
    <>
      <SEO title="Top Internet Service Providers | High-Speed Fiber & Broadband" description='Find the best internet service providers offering fast, high-speed fiber and broadband connections. Explore affordable packages and providers near you.' keywords="internet services provider,internet broadband service, internet broadband service provider, fast internet connection, high speed internet service provider, high speed internet connection, fiber internet packages, fiber internet providers, fiber internet Connection, high speed broadband services, high speed broadband connection, internet providers near me, fiber broadband, high speed broadband, high speed broadband, internet providers" />

      <Layout>
        <Hero />
        {/* <BannerBoxs /> */}
        <DreamInternet />
        <Counting />
        <Services />
        <WeAreLocated />
        <Plans />
        {/* <Expansion /> */}
        <Investors />
        <FAQ />
        <Testimonial />
        <Blogs />
      </Layout>
    </>
  )
}
export default Home