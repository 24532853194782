import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const SEO = ({ title = "", description = "", keywords = "", schema = "", noIndex = false }) => {
  const { pathname } = useLocation();
  console.log("path", pathname)

  return (
    <Helmet>
      <meta charSet="utf-8" />s
      <title>{title || "My Internet "}</title>
      <meta
        name="description"
        content={description || "Find the best internet service providers offering fast, high-speed fiber and broadband connections. Explore affordable packages and providers near you."}
      />
      <meta property="og:title" content={title || "My Internet "} />
      <meta property="og:description" content={description} />
      {noIndex && (
        <meta name="robots" content="noindex, nofollow" />
      )}


      <link rel="canonical" href={`https://www.myinternet.services${pathname}`} />
      {keywords && <meta name="keywords" content={keywords} />}
      {schema && (
        <script defer type="application/ld+json">
          {schema}
        </script>
      )}
    </Helmet>
  );
};

export default SEO;
