import * as React from "react"
 import Layout from "../components/shared/layout"
import Button from "../components/shared/button"
// import { Link } from "react-router-dom"
import { FaCheck } from "react-icons/fa";
import { useEffect } from "react"
import { useNavigate } from "react-router-dom";


const Thanks = () => {
  const navigate = useNavigate(); 
  const currentPathname = localStorage.getItem('currentPathname');
 console.log("location",currentPathname)


  useEffect(() => {
    setTimeout(() => {
      if(currentPathname === "/get-myinternet"){
        navigate("/get-myinternet")
        localStorage.clear()
      }else{
        navigate("/contact-us")
      }
    }, 20000);
  })

  return (
    <>
      <Layout>
        <section>
          <div className="container ">
            <div className="text-center">
              {/* <h1 className="flex items-center justify-center sm:text-[230px] text-[110px] text-[#fff] bg-[#141416] mx-auto  sm:w-[630px] w-full sm:h-[350px] h-[200px] rounded-[10px] mb-[35px]">4 <img className="sm:max-w-[230px] max-w-[110px]" src={ImageIcons.ghostWhite} alt="ghodtwhite" /> 4</h1> */}
              <h1 className="thanks sm:text-[90px] text-[50px] uppercase md:mb-[20px]">Thank You</h1>
              <h2>Our team will be in touch with you soon.</h2>
              <FaCheck className="mx-auto sm:text-[80px] text-[40px] md:mb-[40px] mb-[30px] text-[green]" />
              <Button hrefLink='/' title='Return to Home' />
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default Thanks